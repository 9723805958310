import AlertsService from "@/services/AlertsService";

const state = {
    alertsData: [],
    showAlertModalInMobile: {
        state: false,
        alertToShow: null
    }
}

const getters = {
    alertsData: (state) => state.alertsData,
    showAlertModalInMobile: (state) => state.showAlertModalInMobile
}

const mutations = {
    setShowAlertModalData(state, payload) {
        state.showAlertModalInMobile = payload
    },

    deleteAlert(state, payload) {
        state.alertsData = state.alertsData.filter(
            (ArrayAlert) => ArrayAlert.id !== payload.id
        );
    },

    createAlert(state, payload) {
        state.alertsData.unshift(payload)
    },

    updateAlert(state, payload) {
        const index = state.alertsData.findIndex(
            (alertInArray) => alertInArray.id === payload.id
        );

        if (index !== -1) {
            state.alertsData[index] = payload;
            return
        }
    },

    setAlert(state, payload) {
        state.alertsData = payload
    },

    changeIsAlertReadState(state, payload){
        const index = state.alertsData.findIndex((alertInArray) => alertInArray.id == payload.warning_id)
        state.alertsData[index].is_read = payload.is_read
    },
}

const actions = {

    setShowAlertModalData({commit}, payload) {
        commit('setShowAlertModalData', payload)
    },

    async createAlert({commit, dispatch}, payload) {
        try {
            const response = await AlertsService.CreateAlert(payload.alertData, payload.token)

            commit('createAlert', response.data)
            dispatch(
                'toastfy/activeSuccesNotification',
                {text: "Aviso Criado!"},
                {root: true}
            );
            return response.data
        } catch (error) {
            dispatch(
                'toastfy/activeErrorNotification',
                {text: "Erro ao criar o Aviso, tente novamente!"},
                {root: true}
            );
            throw new Error("Erro ao criar Aviso, tente novamente!")
        }
    },

    async updateAlert({commit, dispatch}, payload) {
        try {
            const response = await AlertsService.UpdateAlertContent(payload.alertData, payload.token)

            commit('updateAlert', response.data)
            dispatch(
                'toastfy/activeSuccesNotification',
                {text: "Aviso Atualizado!"},
                {root: true}
            );
            return response.data
        } catch (error) {
            dispatch(
                'toastfy/activeErrorNotification',
                {text: "Erro ao atualizar o Aviso"},
                {root: true}
            );

            throw new Error("Erro ao atualizar o Aviso, tente novamente!")
        }
    },

    async deleteAlert({commit, dispatch}, payload) {
        try {
            const response = await AlertsService.DeleteAlert(payload.alertData, payload.token)

            commit('deleteAlert', payload.alertData)
            dispatch(
                'toastfy/activeSuccesNotification',
                {text: "Aviso Removido!"},
                {root: true}
            );
            return response.data
        } catch (error) {
            dispatch(
                'toastfy/activeErrorNotification',
                {text: "Erro ao Remover Aviso"},
                {root: true}
            );

            throw new Error("Erro ao deletar o Aviso, tente novamente!")

        }
    },

    async GetAlerts({commit, dispatch}, payload) {
        try {
            const response = await AlertsService.GetAlert(payload.filter, payload.token)
            const payloadToStore = [...response.data].reverse()
            commit('setAlert', payloadToStore)
            return response
        } catch (error) {
            dispatch(
                'toastfy/activeErrorNotification',
                {text: "Erro ao requisitar Avisos, tente novamente!"},
                {root: true}
            );
            throw new Error("Erro ao requisitar Avisos, tente novamente!")
        }
    },

    async changeIsAlertReadState({commit, dispatch}, payload) {
        try {
            await AlertsService.changeAlertReadState(payload)
            commit('changeIsAlertReadState', payload.warningData)
        } catch (error) {
            dispatch(
                'toastfy/activeErrorNotification',
                {text: error},
                {root: true}
            );
        }
    }
}

export default {
    namespaced: true,
    actions,
    state,
    getters,
    mutations
}

