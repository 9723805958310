<template>
  <div :style="{'--font-scale': fontMultiplier, fontFamily: dyslexiaIsActive ? 'OpenDyslexic' : 'Poppins'}">
    <div
        v-if="loadingState"
        class="z-3 vw-100 vh-100 position-fixed d-flex align-items-center justify-content-center">
      <LoaderComponent/>
    </div>
    <div v-if="loadingView !== 'app'" class="d-flex">
      <SideBarComponent v-if="!shouldNotHaveSideBar.includes(route.name)"/>
      <AlertsInMobileItems v-if="route.name === 'My Disciplines' && userWidth < 1024" />
      <div :class="!shouldNotHaveSideBar.includes(route.name) ? 'content-container' : 'full-size-container' ">
        <RouterView v-if="view !== 'app'"/>
        <AcessibilityItems />
      </div>
      <ScrollToTopButton v-show="scrollToTopState"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import 'vue3-toastify/dist/index.css';
import AcessibilityItems from "@/components/items/AcessibilityItems.vue";
import ScrollToTopButton from "@/components/buttons/ScrollToTopButton.vue";
import {useRoute, useRouter} from "vue-router";
import SideBarComponent from "@/components/sidebars/SidebarComponent.vue";
import LoaderComponent from "@/components/loaders/LoaderComponent.vue";
import store from "@/store";
import {computed} from "vue";
import AlertsInMobileItems from "@/components/items/AlertsInMobileItems.vue";

export default {

  data() {
    return {
      showAlertListState: false,
      userWidth: window.innerWidth,
      token: computed(() => store.getters.getToken),
      logOutExpiratioTime: 14400,
      router: useRouter(),
      route: useRoute(),
      shouldNotInteractWidthLogOutTimer: ['Login', 'Forgot Password', 'Error', 'Not Found'],
      shouldNotPing: ['Login', 'Forgot Password'],
      shouldNotHaveSideBar: ['Login', 'Forgot Password', 'Error', 'Not Found'],
      scrollViews: ['Disciplines', 'Questionnaire', 'My personalized disciplines', 'Create Quiz', 'Discipline Material', 'My Disciplines', 'Questionnaire Feedback', 'Quizzes', 'Update Quiz']
    }
  },

  components: {
    AlertsInMobileItems,
    AcessibilityItems,
    LoaderComponent,
    SideBarComponent,
    ScrollToTopButton,
  },

  computed: {
    ...mapGetters({
      dyslexiaIsActive: 'acessibility/dyslexiaIsActive',
      fontMultiplier: 'acessibility/fontMultiplier',
    }),
    ...mapGetters(["isLoading", 'loadingState', 'loadingView']),
    ...mapGetters('scrollToTop', ['scrollToTopState']),
  },

  mounted() {

    if (!this.shouldNotInteractWidthLogOutTimer.includes(this.route.name)) {
      this.startTimeCounter()
    }

    this.userPing()
    this.handleResize()
    this.$store.dispatch('alerts/setShowAlertModalData', {
      state: false,
      alertToShow: null
    })
    this.$store.dispatch('scrollToTop/setScrollToTop', {
      scrollToTopState: false
    })

    document.documentElement.style.fontSize = `${16 * this.fontMultiplier}px`;
    store.dispatch('setLoadingState', {loadignState: false})
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize)
    window.addEventListener("mousemove", this.resetLogOutTimer);
    window.addEventListener("keypress", this.resetLogOutTimer);
    window.addEventListener("click", this.resetLogOutTimer);
    window.addEventListener("touchstart", this.resetLogOutTimer);
  },

  watch: {
    fontMultiplier() {
      document.documentElement.style.fontSize = `${16 * this.fontMultiplier}px`;
    },

    'route.name'(newValue, oldValue) {
      if (oldValue === 'Login' && newValue !== oldValue) {
        this.resetLogOutTimer()
        this.startTimeCounter()
      }
    }
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('mousemove', this.resetLogOutTimer);
    window.removeEventListener('keypress', this.resetLogOutTimer);
    window.removeEventListener('click', this.resetLogOutTimer);
    window.removeEventListener('touchstart', this.resetLogOutTimer);
  },

  methods: {
    startTimeCounter() {

      const invervalId = setInterval(() => {

        if (this.logOutExpiratioTime <= 0 && !this.shouldNotInteractWidthLogOutTimer.includes(this.route.name)) {
          this.router.push({ name: 'Login' });
          localStorage.removeItem('authToken');
          clearInterval(invervalId)
        }

        if (this.logOutExpiratioTime <= 0) {
          clearInterval(invervalId)
        }

        this.logOutExpiratioTime --
      }, 1000)
    },

    resetLogOutTimer() {
      if (!this.shouldNotInteractWidthLogOutTimer.includes(this.route.name) && this.logOutExpiratioTime <= 300) {
        this.logOutExpiratioTime = 14400
      }

    },

    handleResize() {
      this.$store.dispatch('setUserWidth', window.innerWidth)
    },

    userPing() {
      setInterval(() => {
        if (!this.shouldNotPing.includes(this.route.name) && this.token) {
          this.$store.dispatch('pingUser', { userToken: this.token })
              .catch(() => {
                console.log("Realize Login Novamente!")
                this.router.push({ name: 'Login' });
              });
        }
      }, 60000);
    },

    handleScroll() {
      const shouldButtonAppear = this.scrollViews.includes(this.route.name)

      if (window.scrollY > 100) {
        if (shouldButtonAppear) {
          this.$store.dispatch('scrollToTop/setScrollToTop', {
            scrollToTopState: true
          })
        }
      } else {
        this.$store.dispatch('scrollToTop/setScrollToTop', {
          scrollToTopState: false
        })
      }
    },
  }
};
</script>

<style scoped>

.full-size-container {
  width: 100%;
  height: 100%;
}

.content-container {
  width: 0px;
  flex-grow: 1;
  padding: 4% 2%;
}

@media (max-width: 1280px) {
  .content-container {
    padding-left: 2%;
  }

  .content-container {
    padding-top: 15%;
  }
}

</style>