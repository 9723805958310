<script setup>
import {computed, ref, watch} from "vue";
import {useStore} from "vuex";

const store = useStore()
const userConfigSettings = computed(() => store.getters.getConsumerSettings)
const userWidth = computed(() => store.getters.getUserWidth)
const fontMultipler = computed(() => store.getters['acessibility/fontMultiplier'])
const modalState = ref(false)
const dyslexiaIsActive = computed(() => store.getters['acessibility/dyslexiaIsActive'])

const handleDyslexiaState = () => {
  store.dispatch('acessibility/setDyslexiaState', {isActive: !dyslexiaIsActive.value})
}

const handleFontSize = (WhatToDo) => {
  if (WhatToDo === 'increment') {
    store.dispatch('acessibility/setIncrementMultiplier', { userWidth: userWidth.value })
    return
  }

  store.dispatch('acessibility/setDecrementFontMultiplier')
}

const handleModalState = () => {
  modalState.value = !modalState.value
}

const handleMaxFontMultiplerBaseInRes = () => {
  fontMultipler.value > 1.6 && userWidth.value < 1024 ? store.dispatch('acessibility/resizeFontMultipler') : ''
}

watch(userWidth, () => {
  handleMaxFontMultiplerBaseInRes()
})

</script>

<template>
  <transition name="fade">
    <button
        :style="{ backgroundColor: userConfigSettings.settings.primary_color }"
        role="button"
        aria-label="Abrir Interface Acessibilidade"
        v-if="!modalState"
        @click="handleModalState"
        class="acessibility-button position-fixed border-0 rounded-3 text-white">
      A
    </button>
  </transition>
  <transition name="accessibility_slide">
    <div
        v-if="modalState"
        class="acessbility-container bg-white  px-4 py-3 position-fixed rounded-3">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h3>
          Acessibilidade
        </h3>

        <button
            @click="handleModalState"
            class="close-button border-0 bg-transparent "><i class="fs-6 bi bi-x-lg"></i></button>
      </div>

      <div class="rounded-3 acessibility-option d-flex justify-content-between px-3 py-2 align-items-center">
        <button
            :style="{ backgroundColor: userConfigSettings.settings.primary_color }"
            @click="handleFontSize('increment')"
            class="text-white border-0 rounded-1">+
        </button>
        <p class="p-0 mx-3 my-0">Ajustar Fonte</p>
        <button
            :style="{ backgroundColor: userConfigSettings.settings.primary_color }"
            @click="handleFontSize('decrement')"
            class="text-white border-0 rounded-1">-
        </button>
      </div>
      <div :style="{'--background-input-color': userConfigSettings.settings.primary_color }"
           class="rounded-3 mt-4 acessibility-option d-flex align-items-center px-3 py-2">
        <label
            class="switch">
          <input
              :checked="dyslexiaIsActive"
              @change="handleDyslexiaState"
              type="checkbox">
          <span class="slider round"></span>
        </label>
        <p class="m-0 p-0 ms-3">Fonte para Dislexia</p>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.acessbility-container {
  right: 10px;
  top: 55%;
  z-index: 10000;
}

.acessbility-container h3 {
  font-size: calc(15px * var(--font-scale));
}

.acessibility-option button {
  height: 20px;
  width: 20px;
  font-size: calc(12px);
}

.acessbility-container p {
  font-size: calc(13px * var(--font-scale));
}

.acessbility-container, .acessibility-option {
  border: 1px solid #D2D8DF;
}

.acessibility-button {
  top: 55%;
  right: 10px;
  text-align: center;
  width: 37px;
  height: 37px;
  font-size: 24px;
  z-index: 10000;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 21px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--background-input-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>