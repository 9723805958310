import { login, forgotPassword } from "@/services/LoginService";

const state = {
  user: {
    id: "",
    name: "",
    type: "",
    company: "",
    token: "",
    authToken: localStorage.getItem("authToken") || null,
    isAdmin: false,
    isAuthenticated: false,
  },
  status: ""
};

const getters = {
  getId: (state) => state.user.id,
  getName: (state) => state.user.name,
  getToken: (state) => state.user.authToken,
  getType: (state) => state.user.type,
  getCompany: (state) => state.user.company,
  isAdmin: (state) => state.user.isAdmin,
  isAuthenticated: (state) => state.isAuthenticated,
  getStatusMessage: (state) => state.status
};

const mutations = {
  setToken(state, token) {
    state.user.authToken = token;
  },
  setId(state, id) {
    state.user.id = id;
  },
  setCompanyId(state, company_id) {
    state.user.company_id = company_id;
  },
  setAdminPermission(state, isAdmin) {
    state.user.isAdmin = isAdmin;
  },
  clearAuthToken(state) {
    state.user.authToken = null;
    localStorage.removeItem("authToken");
  },
  setType(state, type) {
    state.user.type = type;
  },
  setName(state, name) {
    state.user.name = name;
  },
  setAuthenticationStatus(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  setCompany(state, company) {
    state.user.company = company;
  },
  setStatusMessage(state, status) {
    state.status = status;
  }
};

const actions = {
  setAuth({ commit }, data) {
    commit("setToken", data.token);
    commit("setId", data.id);
    commit("setAdminPermission", data.admin); 
    commit("setType", data.type);
  },

  clearUserToken({commit}) {
    commit('clearAuthToken')
  },

  setAuthenticationStatus({ commit }, isAuthenticated) {
    commit('setAuthenticationStatus', isAuthenticated);
  },
  setUserData({ commit }, data) {
    commit("setId", data.id);
    commit("setName", data.name);
    commit("setType", data.type);
    commit("setCompanyId", data.company_id);  
  },
  async doLogin({ commit }, credentials) {
    try {
      const response = await login(credentials);

      commit("setToken", response.data.token);
      commit("setId", response.data.id);
      commit("setAdminPermission", response.data.admin); 
      commit("setType", response.data.type);
      commit("setName", response.data.name);
      commit("setAuthenticationStatus", true);
      commit("setCompany", response.data.company);

      return response;

    } catch (error) {
      console.error("Erro ao efetuar login:", error);
      throw error;
    }
  },

  async forgotPassword({ commit }, credentials) {
    try {
      const response = await forgotPassword(credentials);

      commit("setStatusMessage", "Email de recuperação enviado com sucesso");

      return response;
    } catch (error) {
      console.log("Erro ao recuperar senha: ", error);

      throw error;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
