import ReportsService from "@/services/ReportService";

const state = {
    reportsData: [],
    urlPDF: '',
    getterFilterMockUp: {}
}

const mutations = {
    setReportsData: (state, payload) => {
        state.reportsData = payload
    },

    setUrlPDF: (state, payload) => {
        console.log("URL Set Before: ", state.urlPDF)
      state.urlPDF = payload
        console.log("URL Set After: ", state.urlPDF)
    },

    setGetterFilter: (state, payload) => {
        state.getterFilterMockUp = payload
    },

    resetReportsData: (state) => {
        state.reportsData = []
        state.urlPDF = ''
    }
}

const actions = {
    async getReportsData({commit, dispatch}, payload) {
        try {
            if (!payload.requestData.name && !payload.requestData.startDate && payload.requestData.disciplinesIds.length === 0) {
                await dispatch('toastfy/activeErrorNotification', {text: "Preencha ao menos um dos filtros!"}, {root: true});
                return
            }
            dispatch('setLoadingState', {loadignState: true, loadingView: 'reportsView'}, {root: true});
            const response = await ReportsService.getReports(payload)
            commit('setGetterFilter', payload)
            commit('setReportsData', response.data)

            if (response.data.length === 0) {
                dispatch('toastfy/activeErrorNotification', {text: 'Nenhum registro encontrado!'}, {root: true});
            }
        } catch (error) {
            dispatch('toastfy/activeErrorNotification', {text: error}, {root: true});
        } finally {
            dispatch('setLoadingState', {loadignState: false}, {root: true});
        }

    },

    resetReportsData({commit}) {
        commit('resetReportsData')
    },

    async getReportPDF({state, commit, dispatch}) {
        if (state.reportsData.length === 0) {
            await dispatch('toastfy/activeErrorNotification', {text: 'Nenhum registro encontrado para Dowload!'}, {root: true});
            return;
        }

        try {
            dispatch('setLoadingState', {loadignState: true, loadingView: 'reportsView'}, {root: true});
            const response = await ReportsService.getReportPDF(state.getterFilterMockUp)
            dispatch('toastfy/activeSuccesNotification', {text: 'Relatório Gerado!'}, {root: true});
            window.open(response.url, "_blank");
            commit('setUrlPDF', response.url)

        } catch (error) {
            dispatch('toastfy/activeErrorNotification', {text: error}, {root: true});
        } finally {
            dispatch('setLoadingState', {loadignState: false}, {root: true});
        }
    }
}

const getters = {
    reportsData: (state) => state.reportsData,
    reportURL: (state) => state.urlPDF
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
}