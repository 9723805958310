import AuthService from "./api/AuthService";

export const getConsumers = async (payload, userToken) => {
    try {
        let url = '/consumers?';

        if (payload.search) {
            url += `search=${payload.search}&`;
        }

        if (payload.companyId) {
            url += `company_id=${payload.companyId}&`;
        }

        if (payload.distinctResposibleId) {
            url += `distinct_responsible_id=${payload.distinctResposibleId}&`;
        }

        const response = await AuthService.get(url.slice(0, -1), {
            headers: {
                'Authorization': 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao buscar consumidores', error);

        throw error;
    }
}


export const createConsumer = async (payload, userToken) => {
    try {
        const response = await AuthService.post('/consumers', payload, {
            headers: {
                'Authorization': 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao adicionar consumidor', error);

        throw error;
    }
}

export const getSessionData = async (payload) => {
    try {
        const response = await AuthService.get(`/sessions/${payload.userId}`, {
            headers: {
                'Authorization': 'Bearer ' + payload.userToken
            }
        })

        if (!response.data.data) {
            return response
        }
        
        return response.data
    } catch {
        throw new Error("Erro ao requisitar dados da sessão")
    }
}

export const getConsumerBadges = async (payload) => {
    try {
        const response = await AuthService.get(`/badges/users/${payload.userId}`, {
            headers: {
                'Authorization': 'Bearer ' + payload.userToken
            }
        })

        return response.data
    } catch {
        throw new Error("Erro ao requisitar Badges")
    }
}

export default {
    getConsumers,
    createConsumer,
    getSessionData,
    getConsumerBadges
}