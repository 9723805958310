import ConsumersService from "@/services/ConsumersService";

export default {
    state() {
        return {
            newConsumer: [],
            consumer: [],
            consumersData: [],
            status: null,
            sessionData: [],
            consumerBadges: []
        }
    },

    getters: {
        getSessionData: (state) => state.sessionData,
        getConsumerBadges: (state) => state.consumerBadges,
        getNewConsumerName: (state) => state.newConsumer,
        getConsumerData: (state) => state.consumer,
        getConsumersData: (state) => state.consumersData
    },

    mutations: {
        setConsumerBadges(state, payload) {
            state.consumerBadges = payload
        },

        setConsumerData(state, data) {
            state.consumer = data;
        },
        setConsumersData(state, data) {
            state.consumersData = data;
        },
        setNewConsumer(state, consumer) {
            state.newConsumer = consumer;
        },
        setStatus(state, status) {
            state.status = status;
        },
        setSessionData(state, payload){
            state.sessionData = payload
        }
    },

    actions: {
        async getConsumers({ commit }, payload) {
            try {
                const response = await ConsumersService.getConsumers(payload.data, payload.userToken);

                commit('setConsumersData', response.data);
            } catch (error) {
                console.error('Erro ao buscar consumidores', error);

                commit('setStatus', 'Erro ao buscar consumidores');
            }
        },

        async createConsumer({ commit }, payload) {
            try {
                const response = await ConsumersService.createConsumer(payload.consumer, payload.userToken);

                commit('setNewConsumerData', response);

                commit('setStatus', 'Consumidor adicionado com sucesso');
            } catch (error) {
                console.error('Erro ao adicionar consumidor', error);

                commit('setStatus', 'Erro ao adicionar consumidor');
            }
        },

        async getSessionData({commit, dispatch}, payload) {
            try {
                const response = await ConsumersService.getSessionData(payload)
                commit('setSessionData', response.data)
            } catch (error) {
                dispatch('toastfy/activeErrorNotification', {text: error}, {root: true});
            }
        },

        async getConsumerBadges({commit, dispatch}, payload) {
            try {
                const response = await ConsumersService.getConsumerBadges(payload)
                commit('setConsumerBadges', response.data)
            } catch (error) {
                dispatch('toastfy/activeErrorNotification', {text: error}, {root: true});
            }
        }

    }
}