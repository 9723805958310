<script setup>
/* eslint-disable no-undef */

const props = defineProps({
  alert: { type: Object, required: true},
  userColor: { type: String, required: true}
})

const formatTimestampToDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses começam em 0
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const getBackgroundColor = () => {
  if (!props.alert.is_read) {
    return props.userColor
  }

  return '#78748680'
}

/* eslint-enable no-undef */
</script>

<template>
  <div class="d-flex my-3 w-100 mobile-warning-container">
    <div :style="{ backgroundColor: getBackgroundColor() }" class="warning-column h-100"></div>
    <div class="px-3 flex-grow-1 d-flex flex-column justify-content-center h-100 bg-white warning-body">
      <p class="mb-1 warning-name"> {{ formatTimestampToDate(alert.created_at) }} </p>
      <p class="mb-0 warning-date">{{ alert.title }}</p>
    </div>
  </div>
</template>

<style>
.warning-name {
  font-size: 12px;
}

.warning-date {
  font-size: 10px;
}

.warning-body {
  border-radius: 0px 10px 10px 0px;
}

.warning-column {
  border-radius: 10px 0px 0px 10px;
  width: 14px;
}

.mobile-warning-container {
  height: 60px;
}
</style>
<script setup lang="ts">
</script>