const state = {
    dyslexiaIsActive: false,
    fontMultiplier: 1
}

const mutations = {
    setDyslexiaState(state, payload) {
        state.dyslexiaIsActive = payload.isActive
    },

    incrementFontMultiplier(state) {
        state.fontMultiplier = parseFloat((state.fontMultiplier + 0.1).toFixed(1));
    },

    decrementFontMultiplier(state) {
        state.fontMultiplier = parseFloat((state.fontMultiplier - 0.1).toFixed(1));
    },

    resizeFontMultipler(state) {
        state.fontMultiplier = 1.6
    }
}

const actions = {

    resizeFontMultipler({commit}) {
        commit('resizeFontMultipler')
    },

    setDyslexiaState({commit}, payload) {
        commit('setDyslexiaState', payload)
    },

    setIncrementMultiplier({commit, dispatch, state}, payload) {

        if ((state.fontMultiplier < 2 && payload.userWidth >= 1024) || (state.fontMultiplier < 1.6 && payload.userWidth < 1024)) {
            commit('incrementFontMultiplier')
            return
        }
        dispatch(
            'toastfy/activeErrorNotification',
            { text: "Tamanho máximo atingido" },
            { root: true }
        );
    },

    setDecrementFontMultiplier({commit, dispatch, state}) {
        if (state.fontMultiplier > 1) {
            commit('decrementFontMultiplier')
            return
        }
        dispatch(
            'toastfy/activeErrorNotification',
            { text: "Tamanho mínimo atingido" },
            { root: true }
        );
    }
}

const getters = {
    dyslexiaIsActive: (state) => state.dyslexiaIsActive,
    fontMultiplier: (state) => state.fontMultiplier
}

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}