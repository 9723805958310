<script setup>
import {useStore} from "vuex";

/* eslint-disable no-undef */
import {computed, onMounted, ref, watch} from "vue";
import WarningMobileCard from "@/components/cards/WarningMobileCard.vue";
import LoadContentButton from "@/components/buttons/LoadContentButton.vue";

const store = useStore()
const alertListState = ref(false)
const consumerSettings = computed(() => store.getters.getConsumerSettings)
const alertsData = computed(() => store.getters["alerts/alertsData"])
const token = computed(() => store.getters.getToken)
const existsNotReadAlerts = ref(false)
const companyData = computed(() => store.getters.getCompany)
const warningPerPage = ref(30)

const incrementPagination = () => {
  warningPerPage.value += 30
}

const getWarnings = async () => {
  await store.dispatch('alerts/GetAlerts', {
    filter: {company_id: companyData.value.id, status: 'active', perPage: warningPerPage.value},
    token: token.value
  })
}

const handleExistsNotReadAlerts = () => {
  const mockUpVariable = ref(false)

  alertsData.value.forEach((alertInArray) => {
    if (!alertInArray.is_read) {
      mockUpVariable.value =  true
    }
  })

  existsNotReadAlerts.value = mockUpVariable.value
}

const handleAlertState = () => {
  alertListState.value = !alertListState.value
}



const handleAlertModalState = (alertToShow) => {
  store.dispatch('alerts/setShowAlertModalData', {
    state: true,
    alertToShow: alertToShow
  })
}

onMounted(() => {
  handleExistsNotReadAlerts()
})

watch(warningPerPage, () => {
  getWarnings()
})

watch(alertsData, () => {
  handleExistsNotReadAlerts()
}, { deep: true })

/* eslint-enable no-undef */

</script>

<template>
  <div class="z-3 alerts-container position-absolute">
    <div class="d-flex justify-content-end">
      <div v-if="existsNotReadAlerts" class="active-notification position-absolute"></div>
      <i @click="handleAlertState" :style="{ color: consumerSettings.settings.primary_color }" class="bi bi-bell fs-4"></i>
    </div>

    <div v-if="alertListState" class="mt-1 py-3 rounded-3 alertListModal overflow-y-auto">
      <h4 class="fs-6 ms-2">Avisos</h4>
      <div :style="{ backgroundColor: consumerSettings.settings.primary_color }" class="line"></div>
      <WarningMobileCard
          v-for="alert in alertsData"
          :key="alert.id"
          :alert="alert"
          :userColor="consumerSettings.settings.primary_color"
          @click="handleAlertModalState(alert)"
      />

      <div class="w-100 d-flex justify-content-center">
        <LoadContentButton v-if="alertsData.length === warningPerPage" @click="incrementPagination"/>
      </div>
    </div>
  </div>
</template>

<style>
.line {
  height: 3px;
}

.alertListModal {
  padding: 0px 25px;
  width: 275px;
  height: 450px;
  background-color: var(--blue-4);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.alertListModal::-webkit-scrollbar {
  width: 11px;
}

.alertListModal::-webkit-scrollbar-track {
  background: #F7F5F5;
  border-radius: 20px;
}

.alertListModal::-webkit-scrollbar-thumb {
  width: 11px;
  background-color: #1B557A24;
  border-radius: 20px;
}


.bi-bell {
  cursor: pointer;
}

.active-notification {
  background-color: red;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  right: 0px;
  top: 3px;
}

.alerts-container {
  right: 2%;
  top: 15px;
}
</style>