import {ref} from "vue";
import AuthService from "@/services/api/AuthService";

const getFinalUrl = (payload, startUrl) => {
    const url = ref(startUrl)

    if (payload.requestData.name.trim() !== '') {
        url.value += `&name=${payload.requestData.name}`
    }

    if (payload.requestData.startDate.trim() !== '') {
        url.value += `&start_date=${payload.requestData.startDate}`
    }

    if (payload.requestData.endDate.trim() !== '') {
        url.value += `&end_date=${payload.requestData.endDate}`
    }

    if (payload.requestData.disciplinesIds.length > 0) {
        const disciplinesIds = payload.requestData.disciplinesIds
            .filter(id => id !== undefined && id !== null)
            .map(id => `disciplines_ids[]=${id}`)
            .join('&')

        url.value += `&${disciplinesIds}`;
    }

    return url
}

export const getReports = async (payload) => {
    const url = getFinalUrl( payload ,`/logs/student?&per_page=30&page=${payload.requestData.page}`)

    try {
        const response = await AuthService.get(url.value, {
            headers: {
                Authorization: `Bearer ${payload.userToken}`
            }
        })

        return response.data
    } catch {
        throw new Error('Erro ao requisitar relatórios! Tente novamente')
    }
}

export const getReportPDF = async (payload) => {

    const url = getFinalUrl(payload, `/logs/student/pdf?`)
    try {
        const response = await AuthService.get(url.value, {
            headers: {
                Authorization: `Bearer ${payload.userToken}`
            }
        } )
        return response.data
    } catch (error) {
        throw new Error('Erro ao gerar relatório! Tente novamente')
    }
}

export default {
    getReports,
    getReportPDF
}