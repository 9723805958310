<template>
  <button class="btn btn-primary btn-sm" @click="loadContent" :disabled="spinningButton"
          :class="{ 'btn-active': spinningButton }">
        <span v-if="isLoading">
            Carregar mais <span class="loader"></span>
        </span>
    <span v-else>
            Carregar mais <i class="bi bi-arrow-clockwise"></i>
        </span>
  </button>
</template>

<script>
export default {
  name: 'LoadContentButton',
  data() {
    return {
      spinningButton: false
    }
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    isLoading: {
      handler(newData) {
        if (newData) {
          this.spinningButton = true;
          setTimeout(() => {
            this.spinningButton = false;
          }, 1000);
        }
      },
      immediate: true
    }
  },
  methods: {
    loadContent() {
      this.$emit('loadContent');
    }
  }
}
</script>

<style scoped>
.loader {
  width: 14px;
  height: 14px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-active {
  background-color: var(--blue-1);
  border: none;
  opacity: 0.8;
}
</style>